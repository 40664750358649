import React, { useState, useEffect } from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import axios from 'axios'

import "./styles/header.scss";

const Menu = ({pointerEvents}) => {
  const [overlayMenu, setoverlayMenu] = useState([]);
  const [secondaryMenu, setsecondaryMenu] = useState([]);

  const [facebook, setfacebook] = useState([]);
  const [twitter, settwitter] = useState([]);
  const [instagram, setinstagram] = useState([]);
  const [linkedin, setlinkedin] = useState([]);

  const [address, setaddress] = useState([]);
  const [email, setemail] = useState([]);

  const [load, setLoad] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    axios.all([
      axios.get('/wp-json/menus/v1/menus/4'),
      axios.get('/wp-json/menus/v1/menus/3'),
      axios.get('/wp-json/acf/v2/options/')
    ])
        .then(res => {
            setoverlayMenu(res[0].data.items);
            setsecondaryMenu(res[1].data.items);
            setfacebook(res[2].data.acf.facebook);
            settwitter(res[2].data.acf.twitter);
            setinstagram(res[2].data.acf.instagram);
            setlinkedin(res[2].data.acf.linkedin);
            setaddress(res[2].data.acf.address);
            setemail(res[2].data.acf.email);

            setLoad(true);
        })
        .catch(err => {
            setError(err.message);
            setLoad(true)
        })
        

}, []);

    const conatctInfo = address + '<br><a href="mailto:'+ email +'" target="_blank">'+email +'</a>';
    return (
        <div id="menu" className="menu-wrapper" style={{pointerEvents, opacity:0}}>
            <Container>
              <Row className="align-items-md-center flex-column flex-md-row">
                <div className="col-auto col-md-6 col-lg-6 order-2 order-md-1 align-self-end align-self-md-center left-side">
                  <Row className="align-items-center">
                    <Col xs md="8" lg="12" className="align-self-end">
                      { address  &&
                        <p dangerouslySetInnerHTML={{ __html: conatctInfo }} >
                        </p>
                      }
                      <div className="social-links">
                        {facebook != '' &&
                          <a href={facebook} className="social-link facebook" aria-label="facebook"></a>
                        }
                        {twitter != '' &&
                          <a href={twitter} className="social-link twitter" aria-label="twitter"></a>
                        }
                        {instagram != '' &&
                          <a href={instagram} className="social-link instagram" aria-label="instagram"></a>
                        }
                        {linkedin != '' &&
                          <a href={linkedin} className="social-link linkedin" aria-label="linked in"></a>
                        }
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="col col-md-6 col-lg-6 order-1 order-md-2 right-side">
                  <Row className="align-items-center align-items-lg-start">
                    <Col xs lg="12" className="align-self-center align-self-lg-start">
                      {overlayMenu &&
                        <nav className="menu-nav-main">
                          <ul>
                            { overlayMenu.map(item => {

                            if (item.type_label == 'Project') {
                              return (
                                <li key={item.ID}>
                                  <Link to={`/work/${item.slug}`} key={item.ID} >{ item.title }</Link>
                                </li>
                              )

                            } else{

                              if (item.type == 'post_type') {
                                return (
                                  <li key={item.ID}>
                                    <Link to={`/${item.slug}`} key={item.ID} aria-label={item.title}>{ item.title }</Link>
                                  </li>
                                )
                              } else {
                                return (
                                  <li key={item.ID}>
                                    <Link to={ item.url } key={item.ID} aria-label={item.title}>{ item.title }</Link>
                                  </li>
                                )
                              }

                             }

                             
                            })}
                          </ul>
                        </nav>
                      }
                      {secondaryMenu &&
                        <nav className="menu-nav-secondary">
                          <ul>
                            { secondaryMenu.map(item => {
                              
                              if (item.type_label == 'Project') {
                                return (
                                  <li key={item.ID}>
                                    <Link to={`/work/${item.slug}`} key={item.ID} >{ item.title }</Link>
                                  </li>
                                )
  
                              } else{ 


                                if (item.type == 'post_type') {
                                  return (
                                    <li key={item.ID}>
                                      <Link to={`/${item.slug}`} key={item.ID} aria-label={item.title}>{ item.title }</Link>
                                    </li>
                                  )
                                } else {
                                  return (
                                    <li key={item.ID}>
                                      <Link to={ item.url } key={item.ID} aria-label={item.title}>{ item.title }</Link>
                                    </li>
                                  )
                                }

                              }


                            })}
                          </ul>
                        </nav>
                      }
                    </Col>
                  </Row>
                </div>
              </Row>
            </Container>
        </div>
    )


}

export default Menu
